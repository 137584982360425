/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import reducer from './reducer';

const reducers = combineReducers({
	reducer
});

export default reducers;