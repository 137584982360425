import React from 'react';
import { Link } from "react-router-dom";
import '../App.scss';
import { APIURL, enforceHTTPS } from '../constants';
import Skeleton from '../components/Skeleton';

export default class Collections extends React.Component {
  constructor() {
    super(...arguments);
    this.textInput = React.createRef();

    this.state = {
      collections: [],
      isLoading: true,
    };
  }

  async componentDidMount() {

    let data = await this.fetchCollections();

    this.setState({
      collections: data.collections
    }, this.onLoaded);
  }

  async fetchCollections() {
    const response = await fetch(`${APIURL}/collections`, {
      method: 'GET'
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  }

  onLoaded = () => {
    this.setState({
      isLoading: false
    });
  }

  render() {
    const { collections, isLoading } = this.state;


    const result = collections ? Object.keys(collections).map((key, index) => {

      const collection = collections[key];
      const backgroundImage = collection.cover_photo ? `url( ${enforceHTTPS(collection.cover_photo)} )` : '';

      const id = collection.id ? collection.id : null;

      // some unsplash collections are marked as private
      const privacy = collection.private && collection.private === true ? true : false;

      let result = '';

      if (!privacy) {
        result = <Link className="is-result-item fade-in animated" key={index} to={`/collection/${id}/${collection.title}`}>
          <li className="image-grid-item" style={{ backgroundImage }}>
            <div className="image-title text-center">{collection.title}</div>
          </li>
        </Link>
      }


      return result;

    }) : [];

    const loader = [...Array(6)].map((_, i) => {
      return (
        <Skeleton key={i} />
      )
    });

    return (
      <div className={isLoading ? 'is-loading' : ''} >
        <ul className="image-grid mb3">
          {
            !isLoading ? result : loader
          }
        </ul>
      </div>
    );
  }
}
