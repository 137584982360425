import React from 'react';
import logo from '../img/materialised-logo.png';
import { Link } from 'react-router-dom';

function Header() {

	return (
		<header id="header" className="app-header mt2 display-inline-block">
			<a href={'https://materialised.com.au'} className="display-inline-block lh0">
				<img src={logo} alt="Materialised logo" />
			</a>
			<div className="mw-540 mt2">
				<p className="text-med">Materialised supplies commercial trade and professional interior design markets with distinctive, thought-provoking acoustic art. We have carefully curated an image library consisting of collections from local and international artists and photographers.</p>
			</div>

		</header>
	);
}

export default Header;