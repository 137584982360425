import React, { Component } from 'react';
import RemoveIcon from './../img/remove.svg';
import { getAllEntries } from '../firebase';
import Papa from 'papaparse';

class EntriesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: [],
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.fetchEntries();
        }
    }

    fetchEntries = async () => {
        this.setState({ loading: true });
        try {
            const entries = await getAllEntries();
            this.setState({ entries, loading: false });
        } catch (error) {
            console.error("Error fetching entries: ", error);
            this.setState({ loading: false });
        }
    }

    formatDate = (timestamp) => {
        if (!timestamp) return "N/A"; // Handle case where timestamp might be undefined
        const date = timestamp.toDate();
        return date.toLocaleString();
    }

    exportToCSV = () => {
        const { entries } = this.state;
        const formattedEntries = entries.map(entry => {
            console.log(entry);
            if (entry) {
                const { id, entry: entryData } = entry;
                if (entryData) {
                    const formattedDate = entryData.createdAt ? this.formatDate(entryData.createdAt) : 'N/A';
                    return {
                        id,
                        from: entryData.from,
                        subject: entryData.subject,
                        content: entryData.content.replace(/(<([^>]+)>)/gi, ""), // Strip HTML tags for CSV
                        createdAt: formattedDate,
                        // Add other fields as necessary
                    };
                }
            }
        });

        console.log(formattedEntries);

        const csv = Papa.unparse(formattedEntries);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'entries.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        const { isOpen, onClose } = this.props;
        const { entries, loading } = this.state;

        if (!isOpen) return null;

        return (
            <div className={`modal enquire-modal ${isOpen ? 'is-open' : ''}`}>
                <div className="modal-main">
                    <div className="modal-content">
                        <img
                            alt="remove"
                            className="close-widget position-absolute pointer"
                            width="12px"
                            height="12px"
                            src={RemoveIcon}
                            onClick={onClose}
                        />
                        <h2>Entries</h2>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div>
                                <button onClick={this.exportToCSV}>Export to CSV</button>
                                <ul>
                                    {entries.map((entry) => (
                                        entry.entry ? (
                                            <li key={entry.id}>
                                                <p>Date: {this.formatDate(entry.entry.createdAt)}</p>
                                                <p>From: {entry.entry.from}</p>
                                                <p>Subject: {entry.entry.subject}</p>
                                                <div dangerouslySetInnerHTML={{ __html: entry.entry.content }} />
                                                {/* Add more fields as necessary */}
                                            </li>
                                        ) : (
                                            <li key={entry.id}>
                                                <p>Invalid entry format</p>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EntriesModal;
