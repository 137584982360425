const isDev = false;

export const APIURL = isDev
  ? 'https://materialised.com.au/wp-json/media-library/v2'
  : 'https://materialised.com.au/wp-json/media-library/v2';

// http://materialised.test/wp-json/media-library/v1
export const SHOW_PRICING = false;
export const EMAIL_RECIPIENT = isDev
  ? 'rhys@theitteam.com.au'
  : 'acoustics@materialised.com';
export const BCC_RECIPIENT = isDev ? 'rhys.clay@gmail.com' : 'belinda.price@materialised.com, matt.price@materialised.com';
export const MANAGER = 'designteam@materialised.com';
export const COMING_SOON = true;

export const BUTTON_TEXT = 'Trade - Request Quote';

// replaces any references to http with https
export const enforceHTTPS = (url = '') => {
  if (url) {
    return url.replace(/^http:\/\//i, 'https://');
  }
};

export const APP_VERSION = '2.3.2';