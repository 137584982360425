import React from 'react';
import { Link } from 'react-router-dom';
import '../App.scss';
import { APIURL, SHOW_PRICING, enforceHTTPS, BUTTON_TEXT } from '../constants';

import oak from '../img/Oak_Corner.jpg';
import white from '../img/White_Corner.jpg';
import black from '../img/Black_Corner.jpg';
import white_paper_frame from '../img/White_Corner_Paper.jpg';
import black_paper_frame from '../img/Black_Corner_Paper.jpg';
import oak_paper_frame from '../img/Oak_Corner_Paper.jpg';
import none from '../img/No_Corner.jpg';

import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import actions from '../actions/actions';

import classnames from 'classnames';

class Photo extends React.Component {
  constructor() {
    super(...arguments);

    this.id = this.props.match.params.id;
    this.source = this.props.match.params.source;

    this.allPricing = [];
    this.url = window.location.origin;

    /**
     * Default state
     */
    this.state = {
      photo: {},
      selectedPrintType: 'Acoustic art',
      selectedFrameOption: 'White',
      selectedFrameOrientation: 'Portrait',
      selectedSize: '',
      selectedSizeIndex: 0,
      customSize: '',
      pricing: [],
      quantity: 1,
      price: null,
      isLoading: true,
      selectedPhoto: this.props.selectedPhoto,
      additionalRequirements: '',
      addWhitePaperBoard: false,
      addAntiReflection: false,
      addSecurityFixtures: false,
    };
  }

  async componentWillReceiveProps(nextProps) {
    const newID = nextProps.match.params.id;
    if (this.id !== newID) {
      this.id = newID;
      this.source = nextProps.match.params.source;
      this.onPropsChange();
    }
  }

  componentDidMount() {
    // console.log('mounted');
    window.scrollTo(0, 0); // fixes scroll issue on some devices 
    this.onPropsChange();
  }

  async onPropsChange() {
    this.fetchPhoto();
    this.allPricing = await this.fetchPricing();

    this.setState(
      {
        pricing: this.allPricing['Acoustic art'], // set pricing to acoustic art to begin with
        selectedSize: `${this.allPricing['Acoustic art'][0].width}mm x ${this.allPricing['Acoustic art'][0].height}mm`,
      },
      this.calculatePricing,
    );
  }

  async fetchPhoto() {

    const data = {
      id: this.id,
      source: this.source,
    };

    const response = await fetch(`${APIURL}/photo`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    // here we check to see if the photo has changed
    // usually if going from one photo to another
    // if so lets update the photo
    const newPhoto =
      body.photo !== this.state.selectedPhoto
        ? body.photo
        : this.state.selectedPhoto;

    // update our selected photo
    this.props.actions.selectPhoto(body.photo);

    this.setState({
      photo: body,
      selectedPhoto: newPhoto,
      isLoading: false,
    });
  }

  async fetchPricing() {
    const response = await fetch(`${APIURL}/pricing`, {
      method: 'GET',
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  }

  onSelectCanvasType = event => {
    const type = event.target.value;
    this.setState(
      {
        selectedPrintType: type,
      },
      this.calculatePricing,
    );
  };

  onSelectFrameOption = option => {
    this.setState(
      {
        selectedFrameOption: option,
      },
      this.calculatePricing,
    );
  };

  onSelectFrameOrientation = option => {

    this.setState(
      {
        selectedFrameOrientation: option.target.value,
      },
      this.calculatePricing,
    );
  };

  onSelectSize = event => {
    const index = event.target.value;
    const size = `${this.state.pricing[index].width}mm x ${this.state.pricing[index].height}mm`;

    this.setState(
      {
        selectedSizeIndex: index,
        selectedSize: size,
      },
      this.calculatePricing,
    );
  };

  onEnterCustomSize = event => {
    const size = event.target.value;

    this.setState({
      customSize: size,
    });
  };

  onChangeQuantity = event => {
    this.setState(
      {
        quantity: event.target.value,
      },
      this.calculatePricing,
    );
  };

  onAddAdditionalRequirements = event => {
    const text = event.target.value;

    this.setState({
      additionalRequirements: text
    },
      this.calculatePricing,
    );

  };

  onAddWhitePaperBoard = value => {
    this.setState({
      addWhitePaperBoard: value
    },
      this.calculatePricing,
    );

  };

  onToggleAntiReflective = value => {
    this.setState({
      addAntiReflection: value
    },
      this.calculatePricing,
    );

  };

  onToggleSecurityFixtures = value => {
    this.setState({
      addSecurityFixtures: value
    },
      this.calculatePricing,
    );

  };

  calculatePricing() {
    const {
      selectedPrintType,
      selectedFrameOption,
      selectedSizeIndex,
      quantity,
    } = this.state;

    // i.e. TrueTexture art / 0
    if (this.allPricing[selectedPrintType]) {
      const newPricing = this.allPricing[selectedPrintType]; // our new pricelist object
      const option = newPricing[selectedSizeIndex];
      let newPrice = '';

      if (selectedFrameOption === 'None') {
        newPrice = option['price_frameless'];
      } else {
        newPrice = option['price_frame'];
      }

      this.setState({
        pricing: newPricing,
        price: newPrice * quantity,
      });
    }
  }

  addToList = () => {
    const {
      quantity,
      price,
      selectedFrameOption,
      selectedPrintType,
      selectedFrameOrientation,
      selectedSize,
      customSize,
      photo,
      additionalRequirements,
      addWhitePaperBoard,
      addAntiReflection,
      addSecurityFixtures,
    } = this.state;

    const item = {
      id: photo.id ? photo.id : '',
      quantity,
      price,
      selectedFrameOrientation,
      selectedFrameOption,
      selectedPrintType,
      selectedSize: customSize ? customSize : selectedSize,
      photo: photo.photo,
      link: photo.link,
      additionalRequirements,
      addWhitePaperBoard,
      addAntiReflection,
      addSecurityFixtures,
      sku: photo.sku ? photo.sku : '',
      source: photo.source
    };

    this.props.actions.addToList(item);
  };

  render() {
    const {
      photo,
      selectedPrintType,
      selectedFrameOrientation,
      selectedFrameOption,
      customSize,
      pricing,
      price,
      quantity,
      isLoading,
      selectedPhoto,
      // additionalRequirements,
      addWhitePaperBoard,
      addAntiReflection,
      addSecurityFixtures
    } = this.state;

    const wrapperClass = classnames('single-photo has-sidebar', {
      'is-loading': isLoading,
      'is-landscape': selectedFrameOrientation === 'landscape'
    });

    const src = selectedPhoto
      ? enforceHTTPS(selectedPhoto)
      : enforceHTTPS(photo.photo);

    return (
      <div>
        <div className="text-left display-block">
          <button
            onClick={() => this.props.history.goBack()}
            className="pointer btn is-outline display-inline-block"
          >
            Back to collection
          </button>
        </div>
        <div className={wrapperClass}>
          <div className="single-image">

            <div className="position-relative">
              <img
                alt={photo.title ? photo.title : ''}
                className={`single-image-frame frame-type-${selectedFrameOption.toLowerCase()} print-type-${selectedPrintType.toLowerCase()} is-large-preview`}
                src={src}
              />
              {photo.label && <div className="single-image__label position-absolute has-border-radius">{photo.label}</div>}
            </div>
            {photo.gallery &&
              <div className="single-image__gallery grid grid-3 p1-2 mt1">
                {photo.gallery.map((gallery, index) => {
                  return <img key={gallery.id} alt={gallery.title} src={gallery.url} />
                })}
              </div>
            }
            <div>
              <h3 className="mb1-4">{photo.title ? photo.title : ''}</h3>
              {photo.description &&
                <p className="mt0 single-image-title">{photo.description ? photo.description : ''}</p>
              }
            </div>

            <div className="text-left grid grid-2 mt2">
              {photo.sku &&
                <div>
                  <h4 className="widget-title text-left mb1-2">Photo SKU: <span>{photo.sku}</span></h4>
                </div>
              }
              {photo.user && (
                <div className="text-left">
                  <h4 className="widget-title text-left mb1-2">Photo by:</h4>
                  <span>{photo.user.name}</span>
                </div>
              )}
            </div>

            {photo.collection_list && (
              <div>
                <h4 className="widget-title text-left mb1-2">Collections:</h4>
                <div className="display-flex">
                  {photo.collection_list.map((collection, i) => {
                    return (<Link key={i} className="btn button-secondary display-inline-block has-border-radius btn-small float-left mr1-2" to={`/collection/${collection.term_id}/${collection.name}`}>{collection.name}</Link>)
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="sidebar">
            <div className="frame-type-options widget">
              <h4 className="widget-title">Select art type:</h4>

              <select
                onChange={this.onSelectCanvasType}
                value={selectedPrintType}
              >
                <option value="Acoustic art">ACOUSTIC (Corfu textile)</option>

              </select>

              {/* <Link to="/about" className="display-block cursor-pointer text-small font-italic mt1-2">Click here for more information on our Print options</Link> */}


              <h4 className="widget-title mt2">Select frame option:</h4>
              <div
                className={`pointer frame-type-option ${selectedFrameOption === 'White' ? 'is-selected' : ''
                  }`}
                onClick={() => this.onSelectFrameOption('White')}
              >
                <div className="badge">
                  <img alt="White" src={selectedPrintType !== 'Framed paper art' ? white : white_paper_frame} />

                  <span>White</span>
                </div>
              </div>

              <div
                className={`pointer frame-type-option ${selectedFrameOption === 'Black' ? 'is-selected' : ''
                  }`}
                onClick={() => this.onSelectFrameOption('Black')}
              >
                <div className="badge">
                  <img alt="Black" src={selectedPrintType !== 'Framed paper art' ? black : black_paper_frame} />
                  <span>Black</span>
                </div>
              </div>

              <div
                className={`pointer frame-type-option ${selectedFrameOption === 'Oak' ? 'is-selected' : ''
                  }`}
                onClick={() => this.onSelectFrameOption('Oak')}
              >
                <div className="badge">
                  <img alt="Oak" src={selectedPrintType !== 'Framed paper art' ? oak : oak_paper_frame} />
                  <span>Oak</span>
                </div>
              </div>

              {
                selectedPrintType !== 'Framed paper art' &&
                <div
                  className={`pointer frame-type-option ${selectedFrameOption === 'None' ? 'is-selected' : ''
                    }`}
                  onClick={() => this.onSelectFrameOption('None')}
                >
                  <div className="badge">
                    <img alt="None" src={none} />
                    <span>None</span>
                  </div>
                </div>
              }



              {selectedPrintType === 'Framed paper art' &&
                <>
                  <div className="display-block w100 mb1 mt1">
                    <h3>Additional Requirements</h3>
                  </div>
                  <div className="display-block w100 mb1-2">
                    <h4 className="widget-title mb1-2">Add a white mat board?</h4>
                    <label htmlFor="yes">
                      <input type="checkbox" id="yes" value={addWhitePaperBoard}
                        onChange={() => this.onAddWhitePaperBoard(!addWhitePaperBoard)} />
                      Yes</label>

                  </div>

                  <div className="display-block w100 mb1-2">
                    <h4 className="widget-title mb1-2">Add Anti Reflection? (additional charge)</h4>
                    <label htmlFor="yes">
                      <input type="checkbox" id="yes" value={addAntiReflection}
                        onChange={() => this.onToggleAntiReflective(!addAntiReflection)} />
                      Yes</label>

                  </div>


                </>
              }

              {selectedPrintType !== 'Acoustic art' &&
                <>

                  <div className="display-block w100 mb1-2">
                    <h4 className="widget-title mb1-2">Add security fixtures?</h4>
                    <label htmlFor="yes">
                      <input type="checkbox" id="yes" value={addSecurityFixtures}
                        onChange={() => this.onToggleSecurityFixtures(!addSecurityFixtures)} />
                      Yes</label>

                  </div>
                </>
              }

              <h4 className="widget-title mt1">Preferred layout (depending on image design):</h4>

              <select
                onChange={this.onSelectFrameOrientation}
                value={selectedFrameOrientation}
              >
                <option value="potrait">Portrait</option>
                <option value="landscape">Landscape</option>
              </select>
            </div>

            <div className="widget">

              <h4 className="widget-title">Select size:</h4>
              <select className="w100" onChange={this.onSelectSize}>
                {pricing.map((price, i) => {
                  return (
                    <option key={i} value={i}>
                      {price.width}mm x {price.height}mm
                    </option>
                  );
                })}
              </select>

              <input
                className="w100 mt1-2"
                placeholder="Or enter a custom size e.g. 500mm x 500mm"
                onChange={this.onEnterCustomSize}
                value={customSize}
              ></input>

              <h4 className="mt2 widget-title">Quantity:</h4>
              <input
                className="w100"
                type="number"
                value={quantity}
                onChange={this.onChangeQuantity}
              />
            </div>

            <div className="product-total">
              {SHOW_PRICING && <p>Your total: ${price} ex gst</p>}
              <button
                onClick={this.addToList}
                className="pointer btn button-primary w100"
              >
                {BUTTON_TEXT}
              </button>

              {/* {photo.source === 'urban_road' &&
                <a className="text-small text-center w100 display-block" target="_blank" rel="noopener noreferrer" href="https://www.urbanroad.com.au/">Not Trade? Shop Here</a>
              } */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPhoto: state.reducer.selectedPhoto,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Photo);
