/*
 * src/store.js
 * With initialState
*/
import throttle from 'lodash/throttle';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

import { loadState, saveState } from './actions/localStorage';
const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk)
);

export default function configureStore() {
 return store;
}

store.subscribe(throttle(() => {
  saveState({
    reducer: store.getState().reducer
  });
}, 1000)); 

// persist state
// https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e