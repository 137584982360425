// ADD to list
let actions = {
	addToList: function (item) {
		return {
			type: 'ADD_TO_LIST',
			item: item,
		}
	},

	removeFromList: function (index) {
		return {
			type: 'REMOVE_FROM_LIST',
			index,
		}
	},

	hideList: function () {
		return {
			type: 'CLOSE_LIST',
		}
	},
	showList: function () {
		return {
			type: 'SHOW_LIST',
		}
	},
	toggleList: function () {
		return {
			type: 'TOGGLE_LIST',
		}
	},
	showEnquire: function () {
		return {
			type: 'SHOW_ENQUIRE',
		}
	},
	hideEnquire: function () {
		return {
			type: 'HIDE_ENQUIRE',
		}
	},
	selectPhoto: function (selectedPhoto) {
		return {
			type: 'ON_SELECT_PHOTO',
			selectedPhoto,
		}
	},
	showToast: function (message) {
		return {
			type: 'ON_SHOW_TOAST',
			message,
		}
	},
	hideToast: function () {
		return {
			type: 'ON_HIDE_TOAST',
		}
	},
	showEntriesModal: function () {
		return {
			type: 'ON_SHOW_MODAL',
		}
	},
	hideModal: function () {
		return {
			type: 'ON_HIDE_MODAL',
		}
	},
}


export default actions;