import React from 'react';
import { MANAGER } from '../constants';
import help from './../img/online-support.svg';
import { Link } from 'react-router-dom';

export default class Help extends React.Component {
  render() {
    return (
      <div className="text-center mt1 mw-540 px1">
        <img
          className="has-white-color mb1"
          src={help}
          width="72px"
          height="72px"
          alt="help icon"
        />
        <h4 className="widget-title">Need help?</h4>
        <p>
          Feel free to contact our helpful team member:{' '}
          <a href={`mailto:${MANAGER}`}>{MANAGER}</a>
        </p>
        <Link to="/" className="mt2 display-block">
          Go back
        </Link>
        {/* <button onClick={() => this.props.history.goBack()} className="pointer btn is-outline display-inline-block center-block">Go back</button> */}
      </div>
    );
  }
}
