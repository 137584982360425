import React from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom';
import './App.scss';

import Routes from './routes';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyListDocument } from './components/MyListDocument';
import ListItem from './components/ListItem';

import { SHOW_PRICING, APP_VERSION, BUTTON_TEXT } from './constants';

import RemoveIcon from './img/remove.svg';
import list from './img/list.svg';
// import ComingSoon from './pages/Coming-soon';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from './actions/actions';

import Form from './components/Form';
import Header from './components/Header';
import EntriesModal from './components/EntriesModal';

class App extends React.Component {

  hideList = () => {
    this.props.actions.hideList();
  };

  showList = () => {
    this.props.actions.showList();
  };

  toggleList = () => {
    this.props.actions.toggleList();
  };

  showEnquire = () => {
    this.props.actions.showEnquire();
  };

  hideEnquire = () => {
    this.props.actions.hideEnquire();
  };

  onRemoveItem = index => {
    this.props.actions.removeFromList(index);
  };

  showEntriesModal = () => {
    this.props.actions.showEntriesModal();
    console.log(this.props.showEntriesModal);
  }

  hideModal = () => {
    this.props.actions.hideModal();
  }

  render() {
    const {
      items,
      isListOpen,
      isEnquireOpen,
      toastMessage,
      showToast,
      showEntriesModal
    } = this.props;

    return (
      <Router>
        <div className="App">
          <Header />

          <Routes />

          <footer className={`my-list ${isListOpen ? 'is-open' : ''}`}>
            <Link
              to={'/help'}
              className="help-widget position-absolute pointer"
            >
              {/* <img
                alt="help"
                className=""
                width="16px"
                height="16px"
                src={help}
              /> */}
              help
            </Link>
            <img
              alt="remove"
              className="close-widget position-absolute pointer"
              width="12px"
              height="12px"
              src={RemoveIcon}
              onClick={this.hideList}
            />
            <div className="list-handle pointer display-inline-block" onClick={this.toggleList}>
              <span>My list</span>
              <img
                alt="list"
                className="text-white width-auto ml1-2"
                width="12px"
                height="12px"
                src={list}
              />
            </div>
            <div className="position-relative my-list-content">
              <h4 className="widget-title">My list</h4>

              {items.map((item, index) => {
                console.log(item);
                return (
                  <div className="list-item" key={index}>
                    <Link to={`/photo/${item.id}/source/${item.source}`} className="font-italic">
                      <img
                        className={`single-image-frame frame-type-${item.selectedFrameOption.toLowerCase()}`}
                        alt="materialised library item"
                        src={item.photo}
                      />
                    </Link>

                    <div className="position-relative">
                      <ListItem item={item} />

                      {SHOW_PRICING && (
                        <span className="total">${item.price} ex gst</span>
                      )}

                      <span
                        onClick={() => this.onRemoveItem(index)}
                        className="remove position-absolute web-font pointer"
                      ></span>
                    </div>
                  </div>
                );
              })}

              {items.length ? (
                <div>
                  <button
                    className="pointer btn button-primary w100 display-block"
                    onClick={this.showEnquire}
                  >
                    {BUTTON_TEXT}
                  </button>

                  <PDFDownloadLink
                    className="pointer btn button-secondary w100 display-block"
                    document={<MyListDocument data={items} />}
                    fileName="my-list_materialised-art-frame-app.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download my list'
                    }
                  </PDFDownloadLink>
                </div>
              ) : (
                <p>You do not have any items in your list yet.</p>
              )}
            </div>
          </footer>

          <div
            className={`modal enquire-modal ${isEnquireOpen ? 'is-open' : ''}`}
          >
            <div className="modal-main">
              <div className="modal-content">
                <img
                  alt="remove"
                  className="close-widget position-absolute pointer"
                  width="12px"
                  height="12px"
                  src={RemoveIcon}
                  onClick={this.hideEnquire}
                />
                <Form />
              </div>
            </div>
          </div>

          <div className={`toast ${showToast ? 'is-open' : ''}`}>
            {toastMessage}
          </div>

          <div className="text-small text-uppercase text-muted mb1" onClick={() => this.showEntriesModal()}>
            App version: {APP_VERSION}
          </div>

          <EntriesModal isOpen={showEntriesModal} onClose={() => this.hideModal()} onClick={() => this.showEntriesModal()} />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: state.reducer.items,
    isListOpen: state.reducer.isListOpen,
    isEnquireOpen: state.reducer.isEnquireOpen,
    toastMessage: state.reducer.toastMessage,
    showToast: state.reducer.showToast,
    showEntriesModal: state.reducer.showEntriesModal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
