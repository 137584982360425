const initialState = {
	items: [],
	isListOpen: false,
	isEnquireOpen: false,
	pricing: [],
	toastMessage: '',
	showToast: false,
	entries: [],
	showEntriesModal: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_TO_LIST':
			return {
				...state,
				items: [...state.items, action.item],
				isListOpen: true
			}

		case 'REMOVE_FROM_LIST':
			const newItems = state.items.filter((item, index) => {
				return index !== action.index;
			});

			return {
				...state,
				items: newItems
			}

		case 'CLOSE_LIST':
			return {
				...state,
				isListOpen: false,
			}

		case 'SHOW_LIST':
			return {
				...state,
				isListOpen: true,
			}

		case 'TOGGLE_LIST':
			return {
				...state,
				isListOpen: !state.isListOpen,
			}

		case 'SHOW_ENQUIRE':
			return {
				...state,
				isEnquireOpen: true,
			}

		case 'HIDE_ENQUIRE':
			return {
				...state,
				isEnquireOpen: false,
			}

		case 'ON_SELECT_PHOTO':
			return {
				...state,
				selectedPhoto: action.selectedPhoto,
			}

		case 'ON_SHOW_TOAST':
			return {
				...state,
				showToast: true,
				toastMessage: action.message,
			}

		case 'ON_HIDE_TOAST':
			return {
				...state,
				showToast: false,
				toastMessage: '',
			}

		case 'ON_SHOW_MODAL':
			return {
				...state,
				showEntriesModal: true
			}

		case 'ON_HIDE_MODAL':
			return {
				...state,
				showEntriesModal: false,
			}

		default:
			return state;

	}
}

export default reducer