import React from 'react';

export default class Farbics extends React.Component {
  render() {
		return (
		<div className="text-center mt1">
			<h3>Fabric options</h3>
			<p>Please click on the links below for more information on each fabric.</p>

			<div className="text-left grid grid-2 mw-540 mb2">
				<div>
					<h4 className="widget-title">Non-acoustic</h4>
					<ul className="list-unstyled pl0">
						<li><a rel="noopener noreferrer" href="https://materialised.com.au/product/peau-de-peche-white/" target="_blank">Peau de peche</a></li>
						<li><a rel="noopener noreferrer" href="https://materialised.com.au/product/corfu/" target="_blank">Corfu</a></li>
						<li><a rel="noopener noreferrer" href="https://materialised.com.au/product/arizona/" target="_blank">Arizona</a></li>
					</ul>
				</div>

				<div>
					<h4 className="widget-title">Acoustic</h4>
					<ul className="list-unstyled pl0">
						<li><a rel="noopener noreferrer" href="https://materialised.com.au/product/corfu/" target="_blank">Corfu</a></li>
						<li><a rel="noopener noreferrer" href="https://materialised.com.au/product/arizona/" target="_blank">Arizona</a></li>
					</ul>
				</div>
			</div>

			<button onClick={() => this.props.history.goBack()} className="pointer btn is-outline display-inline-block center-block">&larr; Go back</button>
		</div>
		);
	}
}
