import * as firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyDCidbJ3hMSuEj3lUW4oiFHUL44pMbMZWI",
    authDomain: "materialised-image-library.firebaseapp.com",
    databaseURL: "https://materialised-image-library.firebaseio.com",
    projectId: "materialised-image-library",
    storageBucket: "materialised-image-library.appspot.com",
    messagingSenderId: "828826387689",
    appId: "1:828826387689:web:d190839a72989cd070dc78"
});

const db = firebase.firestore();

const addEntry = async (
    entry
) => {

    const result = await db.collection('entries').add({ entry });
    console.log("Document added with ID: ", result.id);
    return result;
}

const getAllEntries = async () => {
    const entries = [];
    const snapshot = await db.collection('entries').get();
    snapshot.forEach((doc) => {
        entries.push({ id: doc.id, ...doc.data() });
    });
    return entries;
}

export { addEntry, getAllEntries };

