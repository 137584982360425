import React from 'react';

export default class About extends React.Component {
	render() {
		return (
			<div className="text-center mt1 mw-740 px1">
				{/* <h1>About the Image Library</h1> */}
				<button onClick={() => this.props.history.goBack()} className="pointer btn is-outline display-inline-block center-block">Go back</button>
			</div>
		);
	}
}
