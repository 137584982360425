import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Font,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { SHOW_PRICING } from '../constants';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    fontFamily: 'Open Sans',
  },
  body: {
    flexGrow: 1,
    padding: 20,
  },
  row: {
    // flexGrow: 1,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  image: {
    width: '20%',
    backgroundColor: 'grey',
    //height: 'auto',
    //display: 'block',
  },
  textHeader: {
    textAlign: 'center',
    fontSize: 14,
  },
  textContainer: {
    padding: 10,
    fontSize: 12,
  },
});

Font.register({
  family: 'Open Sans',
  src: `https://themes.googleusercontent.com/static/fonts/opensans/v6/cJZKeOuBrn4kERxqtaUH3aCWcynf_cDxXwCLxiixG1c.ttf`,
});

export function MyListDocument(props) {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <View>
            <Text style={styles.textHeader}>
              My List (Materialised Acoustic Art Library App)
            </Text>
          </View>
          {props.data
            ? props.data.map((item, index) => {
              return (
                <View key={index} style={styles.row}>
                  <View style={styles.image}>
                    <Image src={item.photo} />
                  </View>
                  <View style={styles.textContainer}>
                    <Text>Quantity: {item.quantity}</Text>
                    <Text>Print type: {item.selectedPrintType}</Text>
                    <Text>Frame color: {item.selectedFrameOption}</Text>
                    {/* <Text>Fabric type: {item.selectedFabricOption}</Text> */}
                    <Text>Print size: {item.selectedSize}</Text>
                    {/* <Text>Frame type: { item.selectedFrameOption }</Text> */}
                    <Text>Orientation: {item.selectedFrameOrientation}</Text>
                    {item.addWhitePaperBoard && <Text className="font-italic">Printed on a white mat board</Text>}
                    {/* <Text>Link: {item.photo}</Text> */}
                    {SHOW_PRICING && (
                      <Text className="total">${item.price} ex gst</Text>
                    )}
                  </View>
                </View>
              );
            })
            : ''}
        </View>
      </Page>
    </Document>
  );
}
