import React from 'react';
import { Link } from 'react-router-dom';
import '../App.scss';
import { APIURL, enforceHTTPS } from '../constants';
import Skeleton from '../components/Skeleton';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import actions from '../actions/actions';

class Collection extends React.Component {
  constructor() {
    super(...arguments);

    this.id = this.props.match.params.id; // e.g. 5107
    this.title = this.props.match.params.title;

    this.state = {
      collection: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    let data = await this.fetchCollection();

    this.setState(
      {
        collection: data.collection,
      },
      this.onLoaded,
    );
  }

  async fetchCollection() {
    const data = {
      id: this.id,
    };

    const response = await fetch(`${APIURL}/collection`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  }

  onSelectPhoto = photo => {
    this.props.actions.selectPhoto(photo.photo);
    this.props.history.push(`/photo/${photo.id}/source/${photo.source}`);
  };

  onLoaded = () => {
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { collection, isLoading } = this.state;

    const result = collection.map(photo => {
      // console.log(photo);
      const title = photo.title ? photo.title : '';
      return (
        <li
          data-id={photo.id}
          onClick={() => this.onSelectPhoto(photo)}
          key={photo.id}
          className="pointer image-grid-item has-image fade-in animated single-collection"
          title={photo.id}
        >
          <img alt={title} src={enforceHTTPS(photo.photo)} />
          <div className="image-title text-center">{photo.title}</div>
        </li>
      );
    });

    const loader = [...Array(6)].map((_, i) => {
      return <Skeleton key={i} />;
    });

    return (
      <div>
        <div className="mt1 mb1">
          <h1 className="mt0 mb0">{this.title}</h1>
          {collection[0] &&
            collection[0].link && ( // get the collection link from the first image
              <a
                className="font-italic mt1-2 display-block"
                target="_blank"
                rel="noopener noreferrer"
                href={collection[0].link}
              >
                <i className="web-font link display-inline mr1-2"></i>Click here
                for more information on {this.title}
              </a>
            )}
          <Link to={'/'} className="btn is-outline display-inline-block">
            Back to all collections
          </Link>
        </div>

        <ul className="image-grid">{!isLoading ? result : loader}</ul>

        {/* <div className="filters fixed-bottom is-on-top">
          <ul className="list-unstyled list-inline filter-tags">
            <li>Abstract</li>
            <li>Scenery</li>
            <li>Architecture</li>
          </ul>

          <Link className="btn btn-outline flex-pull-right" to="/about">What is the Materialised library?</Link>
        </div> */}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Collection);
