import React from 'react';
import { Route } from 'react-router-dom';

import Collection from './../pages/Collection';
import Collections from './../pages/Collections';
import Photo from './../pages/Photo';
import About from './../pages/About';
import Help from './../pages/Help';
import Fabrics from './../pages/Fabrics';

export default class Routes extends React.Component {
  render() {
    return (
      <div className="container mt2 mb2">
        <Route exact path="/" component={Collections} />
        <Route path="/collection/:id/:title" component={Collection} />
        <Route path="/photo/:id/source/:source" component={Photo} />
        <Route path="/about" component={About} />
        <Route path="/help" component={Help} />
        <Route path="/fabrics" component={Fabrics} />
      </div>
    );
  }
}
