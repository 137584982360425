import React from 'react';

export default function ListItem(props) {
  const {
    quantity,
    selectedPrintType,
    selectedSize,
    selectedFrameOption,
    selectedFrameOrientation,
    addWhitePaperBoard
  } = props.item;

  return (
    <div>
      <span className="quantity">{quantity}</span>
      <span>Print type: {selectedPrintType}</span>
      {/* <span>Frame color: { selectedFrameOption }</span> */}
      {/* <span>Fabric type: {selectedFabricOption}</span> */}
      <span>Print size: {selectedSize}</span>
      <span>Frame colour: {selectedFrameOption} </span>
      <span>Orientation: {selectedFrameOrientation}</span>
      {addWhitePaperBoard && <span className="font-italic">Printed on a white mat board</span>}
    </div>
  );
}
